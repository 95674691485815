import React, { useEffect, useRef, useState } from "react";
import { TextField, Box, IconButton, InputAdornment } from "@mui/material";
import { ChatSession, GoogleGenerativeAI } from "@google/generative-ai";
import SendIcon from "@mui/icons-material/Send";
import AgentIcon from "@mui/icons-material/SupportAgent";

import BouncingDotLoader from "../components/layout/bouncingdots";
import BoxDX from "components/layout/boxdx";

import { useNotificationContext } from "context/notificationcontext";

const Dashboard = () => {
  const [prompt, setPrompt] = useState("");
  const [responses, setResponses] = useState<any[]>([
    // { type: "q", value: "hi" },
    // { type: "a", value: "how can i help" },
    // { type: "q", value: "thank you for asking" },
    // {
    //   type: "a",
    //   value: "no issues .... no issues .... no issues .... no issues",
    // },
    // { type: "q", value: "much obliged" },
    // { type: "a", value: "are you sure? realy. do you want to think again" },
  ]);
  const { setError } = useNotificationContext();
  const [loading, setLoading] = useState(false);
  const [chatSession, setChatSession] = useState<ChatSession | null>(null);
  const [lastResponse, setLastResponse] = useState("");

  const scrollRef = useRef<HTMLDivElement>(null);

  const formatBoldText = (str: string) => {
    // Split the string at ** and filter to exclude empty strings
    const parts = str.split(/(\*\*.+?\*\*)/).filter(Boolean);

    return parts.map((part, index) => {
      // Check if the part is wrapped in **
      if (part.startsWith("**") && part.endsWith("**")) {
        return `<strong key=${index}>${part.slice(2, -2)}</strong>`;
      }
      return part; // Return normal text if not wrapped
    });
  };

  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  useEffect(() => {
    let modelName: string = process.env.REACT_APP_MODEL_NAME || "";
    const genAI = new GoogleGenerativeAI(
      process.env.REACT_APP_GEMINI_API_KEY || ""
    );
    const model = genAI.getGenerativeModel({ model: modelName });
    const chat = model.startChat();

    setChatSession(chat);
  }, []);

  const handleSubmit = async () => {
    //    let modelName: string = process.env.REACT_APP_MODEL_NAME || "";

    if (!chatSession) return;

    if (prompt.trim()) {
      setResponses((prevResponses) => [
        ...prevResponses,
        { type: "q", value: `${prompt}` },
      ]);
      setPrompt("");
      setLoading(true);

      // const genAI = new GoogleGenerativeAI(
      //   process.env.REACT_APP_GEMINI_API_KEY || ""
      // );

      // const model = genAI.getGenerativeModel({ model: modelName });

      try {
        //const result = await model.generateContent(prompt);
        //const result = await chatSession.sendMessage(prompt);
        // setResponses((prevResponses) => [
        //   ...prevResponses,
        //   { type: "a", value: `${result.response.text()}` },
        // ]);

        const result = await chatSession.sendMessageStream(prompt);

        const response: string[] = [];

        for await (const item of result.stream) {
          if (item.candidates)
            if (item.candidates.length > 0) {
              if (item.candidates[0].content.parts.length > 0) {
                const text = item.candidates[0].content.parts[0].text ?? "";
                const fText = formatBoldText(text).join("");
                response.push(fText);
                setLastResponse((lastValue: string) => lastValue + "" + fText);
              }
            }
        }
        setResponses((prevResponses) => [
          ...prevResponses,
          { type: "a", value: `${response.join(" ")}` },
        ]);

        setLastResponse("");
      } catch (error) {
        setError(error);
        console.error("Error generating response:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [responses, loading, lastResponse]);

  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        position: "relative",
      }}
    >
      <Box
        sx={{
          flex: 1,
          backgroundColor: "#ededed",
          borderRadius: "4px",
          height: "100%",
          maxHeight: "calc(100vh - 64px - 100px)",
          overflowY: "auto",
          overflowX: "hidden",
          p: 2,
          whiteSpace: "pre-line",
          position: "relative",
        }}
      >
        {responses.map((response, index) => (
          <BoxDX
            id={`resp_${index}`}
            sx={{
              color: "rgb(0,0,0,0.6)",
              marginTop: "5px",
              padding: 2,
              display: "flex",
              backgroundColor: "#FFFFFF",
              my: 1,
              borderRadius: "10px",
              ...(response.type === "q"
                ? {
                    marginLeft: "20px",
                    justifyContent: "flex-end",
                  }
                : {
                    borderColor: "rgb(0, 166, 155, 255)",
                    borderStyle: "solid",
                    borderWidth: 1,
                    marginRight: "20px",
                  }),
            }}
          >
            {response.type === "a" ? (
              <AgentIcon sx={{ mr: 2 }} fontSize="small" color="primary" />
            ) : null}
            {<div dangerouslySetInnerHTML={{ __html: response.value }} />}
          </BoxDX>
        ))}

        {loading && (
          <Box
            display="flex"
            sx={{
              mt: 2,
              color: "rgb(0,0,0,0.6)",
              marginTop: "5px",
              padding: 2,
              display: "flex",
              backgroundColor: "#FFFFFF",
              my: 1,
              borderRadius: "10px",
              borderColor: "rgb(0, 166, 155, 255)",
              borderStyle: "solid",
              borderWidth: 1,
              marginRight: "20px",
            }}
          >
            <AgentIcon sx={{ mr: 2 }} fontSize="small" color="primary" />
            {lastResponse.length > 0 ? (
              <div dangerouslySetInnerHTML={{ __html: lastResponse }} />
            ) : (
              <BouncingDotLoader />
            )}
          </Box>
        )}

        <div ref={scrollRef}></div>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 2,
          py: 1.5,
          borderRadius: "15px",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          name="prompt"
          placeholder="Type your prompt here..."
          onChange={handlePromptChange}
          value={prompt}
          onKeyDown={handleKeyDown}
          sx={{
            bgcolor: "rgb(0, 166, 155, 255)",
            borderRadius: "15px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#F9F6EE",
              },
              "&:hover fieldset": {
                borderColor: "#F9F6EE",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#F9F6EE",
              },
              "& input::placeholder": {
                color: "#FAF9F6",
              },
              "& input": {
                color: "white",
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!prompt.trim()}
                >
                  <SendIcon sx={{ color: "#ededed" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
