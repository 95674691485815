import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GridDX from "../components/layout/griddx";
import BoxDX from "../components/layout/boxdx";
import MainAppBarDX from "../components/business/appbar/mainappbardx";
import NotificationBarDX from "../components/notificationbardx";

const MainTemplate = () => {
  const { i18n } = useTranslation();

  return (
    <BoxDX
      sx={{
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "#FAFBFF",
        display: "flex",
      }}
    >
      <MainAppBarDX />

      <GridDX
        container
        sx={{
          marginTop: 57,
          width: "100%",
        }}
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <GridDX item xs={12} sx={{ height: "100%" }}>
          <NotificationBarDX />
          <Outlet />
        </GridDX>
      </GridDX>
    </BoxDX>
  );
};

export default MainTemplate;
