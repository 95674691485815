import "./App.css";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import LoadingOverlay from "./components/layout/loadingoverlay";
import MainTemplate from "./templates/maintemplate";
import Dashboard from "./pages/dashboard";
import { NotificationContextProvider } from "context/notificationcontext";

function App() {
  return (
    <Router>
      <NotificationContextProvider>
        <ApplicationRoutes />
      </NotificationContextProvider>
    </Router>
  );
}

const ApplicationRoutes = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  if (initialLoading || loading) return <LoadingOverlay />;
  else
    return (
      <Routes>
        <Route element={<MainTemplate />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    );
};

export default App;
