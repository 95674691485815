import React, { useEffect, useState } from "react";
import {
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Tooltip,
  ListItemIcon,
  Avatar,
  Typography,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles"; // Import useTheme hook
import BoxDX from "components/layout/boxdx";
import logo from "../../../assets/logo.png";

export default function MainAppBarDX() {
  const theme = useTheme(); // Use MUI theme

  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const [pageHeading, setPageHeading] = useState("Dashboard");

  const routeMap = new Map<string, string>([["dashboard", "Dashboard"]]);

  useEffect(() => {
    let pathName = location.pathname.substring(1);
    const idPath = pathName.indexOf("/");
    if (idPath !== -1) {
      pathName = pathName.substring(0, idPath);
    }
    updatePageHeading(routeMap.get(pathName));
  }, [location.pathname]);

  const updatePageHeading = async (newPageHeading: string | undefined) => {
    setPageHeading(newPageHeading ?? "Page Title");
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar disableGutters>
        <img
          src={logo}
          alt="Logo"
          style={{
            height: "40px",
            backgroundColor: "white",
            marginLeft: "5px",
          }}
        />
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}></Box>
        <AccountMenu />
      </Toolbar>
    </Box>
  );
}

const AccountMenu: React.FC = (props: any) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [openProfile, setOpenProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => {
    handleClose();
    navigate("/");
  };

  const onProfileClick = () => {
    setOpenProfile(!openProfile);
    handleClose();
  };

  return (
    <React.Fragment>
      <BoxDX
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <Tooltip title="Account settings">
          <>
            {/* <IconButton
            
            size="small"
            aria-controls={props.open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={props.open ? "true" : undefined}
          >
            <IosShareIcon sx={{background:"color", mr:"10px"}}/>
          
          </IconButton>
   */}
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={props.open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={props.open ? "true" : undefined}
            >
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: "rgb(0, 166, 155, 255)",
                }}
              />
            </IconButton>
          </>
        </Tooltip>
      </BoxDX>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={onProfileClick}>
          <ListItemIcon>
            <Avatar sx={{ width: 28, height: 28, backgroundColor: "white" }} /> 
          </ListItemIcon>
          <Typography sx={{ color: theme.palette.text.primary }}>My GPTs</Typography>
        </MenuItem>
        <MenuItem >
          <ListItemIcon>
            <DesignServicesIcon sx={{ width: 28, height: 28, color: "white" }} />
          </ListItemIcon>
          <Typography sx={{ color: theme.palette.text.primary }}>Customize ChatGPT</Typography>
        </MenuItem>
        <MenuItem >
          <ListItemIcon>
            <SettingsIcon sx={{ width: 28, height: 28, color: "white" }} /> 
          </ListItemIcon>
          <Typography sx={{ color: theme.palette.text.primary }}>Settings</Typography>
        </MenuItem>
        <Divider/>
        <MenuItem >
          <ListItemIcon>
            <UpgradeIcon sx={{ width: 28, height: 28, color: "white" }} /> 
          </ListItemIcon>
          <Typography sx={{ color: theme.palette.text.primary }}>Upgrade Plan</Typography> 
        </MenuItem> */}
        <MenuItem onClick={onLogoutClick}>
          <ListItemIcon sx={{ paddingLeft: 0.5 }}>
            <Logout
              fontSize="small"
              sx={{ color: theme.palette.error.main, width: 27, height: 25 }}
            />
          </ListItemIcon>
          <Typography sx={{ color: theme.palette.text.primary }}>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
