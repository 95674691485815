import React from "react";

import { keyframes } from "@emotion/react";
import BoxDX from "./boxdx";

const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
`;

const BouncingDotLoader = () => {
  return (
    <BoxDX sx={{ marginTop: "10px", marginRight: "10px" }}>
      <BoxDX sx={{ display: "flex", marginTop: "10px", marginRight: "15px" }}>
        <BoxDX
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "#00a69b",
            marginTop: "10px",
            animation: `${bounceAnimation} 0.6s infinite ease-in-out`,
          }}
        />
        <BoxDX
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "#00a69b",
            marginTop: "10px",

            animation: `${bounceAnimation} 0.6s infinite ease-in-out 0.2s`,
          }}
        />
        <BoxDX
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "#00a69b",
            marginTop: "10px",
            animation: `${bounceAnimation} 0.6s infinite ease-in-out 0.4s`,
          }}
        />
      </BoxDX>
    </BoxDX>
  );
};

export default BouncingDotLoader;
